<template>
  <div>
    <div class="rounded p-3 mb-3" v-background-3>
      <h4>Rémunération des financeurs</h4>
      <uploader
        :id="'document_financeurs'"
        url="/upload/document"
        file_system="CSE/FINANCEURS"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>

    <div class="rounded p-3 mb-3" v-background-3>
      <h4>Activités sociales et culturelles</h4>
      <uploader
        :id="'document_social_culturel'"
        url="/upload/document"
        file_system="CSE/SOCIAL_CULTUREL"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>

    <div class="rounded p-3 mb-3" v-background-3>
      <h4>Partenariat</h4>
      <uploader
        :id="'document_partenariat'"
        url="/upload/document"
        file_system="CSE/PARTENARIATS"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>

    <div class="rounded p-3 mb-3" v-background-3>
      <h4>Transfert commerciaux et financiers</h4>
      <uploader
        :id="'document_partenariat'"
        url="/upload/document"
        file_system="CSE/TRANSFERTS"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>
  </div>
</template>
<script>
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: { Uploader },
  methods: {},
  mounted() {},
  computed: {},
};
</script>
